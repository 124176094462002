<template>
<div class="forum-wrap">
  <div class="main-forum center">
    <br>
    <ATitle text="会议论坛" icon="2"></ATitle>
    <br>
    <div v-html="content" class="forum-content rich-text">
    </div>
  </div>
</div>
</template>
<script>
export default {
  data() {
    return {
      content: '<div><div>第五届浙江国际智慧交通产业博览会会议论坛部分总体设置为“1+3+13”。<br>“1”即开幕式暨主旨论坛。<br>“3”即3大高规格活动，联动中国国际可持续交通创新和知识中心，同期举办高端对话；联动中国物流与采购联合会，同期举办全国首届绿色物流与供应链发展大会；联动未来交通科创中心，同期举办全省交通科技成果推介会。<br>“13”，即13个配套论坛活动。</div></div><br><p><span><strong>&nbsp;开幕式暨主旨论坛&nbsp;</strong></span></p><p><span><img src="https://itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/news/116983889061284-%E5%BC%80%E5%B9%95%E5%BC%8F.png" alt="" /></span></p><p><span>围绕提升博览会的专业影响力和公认度，开幕式设定&ldquo;宣传片开场、领导致辞、创新发布、签约仪式、成果展示、主旨演讲&rdquo;等议程。</span></p><p>&nbsp;</p><p><span><span><strong>&nbsp;主题论坛&nbsp;</strong></span></span></p><p><span><span><strong><img src="https://itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/news/516983890128377-%E4%B8%BB%E9%A2%98%E8%AE%BA%E5%9D%9B.png" alt="" width="auto" height="auto" /></strong></span></span></p><div>主<span>题论坛立足&ldquo;前沿探索、热点挖掘、行业分享&rdquo;的立体视角，向国内外知名协会、高校、科技巨头等发送办会邀请，结合行业热点和最新成果，打造数场行业风向标杆论坛。</span></div><p>&nbsp;</p>'
    }
  }
}
</script>

<style lang="less" scoped>
.forum-wrap {
  background: url('../assets/views_bg.png') no-repeat scroll bottom / 100%;
}
.forum-content {
  padding: 30px;
  min-height: calc(100vh - 600px);
  img {
    max-width: 100%;
  }
}
</style>
